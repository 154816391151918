<template>
  <div class="deviceMaintenanceDetail">
    <div class="detail">
      <div class="info">
        <div class="infoBan">
          <img src="../img/bgb.png" alt="" />
        </div>

        <div class="info-title">设备信息</div>
        <div class="label" v-if="detailData.serviceNumber">
          <span>维保编号：</span>
          <div class="labelCo">{{ detailData.serviceNumber }}</div>
        </div>
        <div class="info-title2">{{ detailData.deviceName }}</div>
        <div class="label" v-if="detailData.deviceVO">
          <span>规格型号：</span>
          <div class="labelCo">{{ detailData.deviceVO.categoryName }}</div>
        </div>
        <div class="label" v-if="detailData.deviceVO">
          <span>序列号：</span>
          <div class="labelCo">{{ detailData.deviceVO.deviceNum }}</div>
        </div>
        <div class="label" v-if="detailData.deviceVO">
          <span>供应商：</span>
          <div class="labelCo">{{ detailData.deviceVO.supplierName }}</div>
        </div>
        <!-- <div class="label">
          <span>购入日期：</span>
          <div class="labelCo">{{ detailData.patrolStartTime }}</div>
        </div>
        <div class="label">
          <span>维保到期日期：</span>
          <div class="labelCo">{{ detailData.patrolStartTime }}</div>
        </div> -->
      </div>
      <div class="content">
        <div class="infoSure">
          <div class="title">维保信息</div>
          <div class="infoSure-body">
            <div class="btnRoute" @click="handelNavigation(detailData)">
              <div class="btn-l">路线</div>
              <div class="btn-r"><img src="../img/nav.png" alt="" /></div>
            </div>
            <div class="item">
              <div class="item-l">维保内容：</div>
              <div class="item-r">{{ detailData.maintenanceContent }}</div>
            </div>
            <div class="item">
              <div class="item-l">维保区域：</div>
              <div class="item-r">{{ detailData.serviceZone }}</div>
            </div>
            <div class="item">
              <div class="item-l">维保日期：</div>
              <div class="item-r">{{ detailData.maintenanceStartTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="fault-message">
          <div class="title">维保确认事项</div>
          <div class="form-item">
            <div class="form-item-title">备注</div>
            <div class="textarea">
              <v-input
                type="textarea"
                v-model="form.content"
                placeholder="输入备注故障信息"
              ></v-input>
            </div>
            <div class="form-item-title">上传照片</div>
            <div class="photo">
              <v-upload
                :imgUrl.sync="form.image"
                :activityPicture.sync="form.image"
                class="oItem-b_upload"
                :maxCount="6"
                ref="load"
              ></v-upload>
            </div>
          </div>
        </div>
        <div
          class="fault-message"
          v-if="detailData.status == 3 || detailData.status > 3"
        >
          <div class="title">处理完成信息确认</div>
          <div class="form-item">
            <div class="form-item-title">故障类别</div>
            <div class="select" @click="handelClickMajorType">
              <div>
                {{ detailData.majorType ? detailData.majorType : "请选择" }}
              </div>
              <img src="../img/arrow.png" alt="" />
            </div>
            <div class="form-item-title">设备名称</div>
            <div class="select" @click="handelClickDevice">
              <div>
                {{ detailData.deviceName ? detailData.deviceName : "请选择" }}
              </div>
              <img src="../img/arrow.png" alt="" />
            </div>
            <div class="form-item-title">描述</div>
            <div class="textarea">
              <v-input
                type="textarea"
                v-model="detailData.repairDescription"
                placeholder="输入备注故障信息"
              ></v-input>
            </div>
            <div class="form-item-title">上传照片</div>
            <div class="photo">
              <v-upload
                :imgUrl.sync="detailData.repairPic"
                :activityPicture.sync="detailData.repairPic"
                class="oItem-b_upload"
                :maxCount="6"
                ref="load"
              ></v-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dateTimePicker
      type="date"
      :value="form.patrolTime"
      :minDate="new Date(2023, 0, 1)"
      :valueShow.sync="isShowNurseryDate"
      :isAuto="true"
      @confirmPicker="confirmNurseryDate"
    ></v-dateTimePicker>
    <div class="footer" v-if="detailData.status == 0">
      <div class="btn" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  getMaintenanceTaskByIdUrl,
  saveOrUpdateWorkInfo,
  inputPatrolTaskDetailUrl,
  getDeviceList,
} from "./api.js";
import { oStatusMap, oStatus } from "./map.js";
export default {
  name: "deviceMaintenanceDetail",
  components: {},
  props: {},
  data() {
    return {
      isShowNurseryDate: false,
      oStatusMap,
      isShowMajorType: false,
      isShowDevice: false,
      eventKey: "label",
      eventLabel: "deviceName",
      deviceNameList: [],
      active: 0,
      detailData: {},
      formList: {
        sceneDescription: "",
      },
      form: { patrolId: "", patrolTime: "请选择", content: "", image: "" },
      stageSts: [],
      oIndex: [],
      id: "",
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    // this.getDeviceList();
  },
  mounted() {
    const { id } = this.$route.query;
    this.id = id;
    if (id != undefined) {
      this.getWorkInfoById(id);
    }
  },
  methods: {
    async submit() {
      // if (!this.form.patrolId) {
      //   this.$toast({ message: "请选择巡检区域", duration: 2000 });
      //   return;
      // }
      //   if (!this.form.patrolTime || this.form.patrolTime == "请选择") {
      //     this.$toast({ message: "请选择时间", duration: 2000 });
      //     return;
      //   }
      if (!this.form.content) {
        this.$toast({ message: "请填写备注", duration: 2000 });
        return;
      }
      if (!this.form.image) {
        this.$toast({ message: "请上传图片", duration: 2000 });
        return;
      }
      let params = {
        content: this.form.content,
        patrolId: this.form.patrolId,
        patrolTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        image: this.form.image,
        createUser: this.userId,
        id: this.id,
      };
      let res = await this.$axios.post(`${inputPatrolTaskDetailUrl}`, params, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      if (res.code == 200) {
        await this.getWorkInfoById(this.id);
        window.scrollTo(0, 0);
      }
    },
    selectTime() {
      this.isShowNurseryDate = true;
    },
    confirmNurseryDate(val) {
      this.form.patrolTime = moment(val).format("YYYY-MM-DD");
    },
    change() {},
    //获取设备列表
    getDeviceList() {
      const params = {
        curPage: 1,
        pageSize: 100000,
      };

      this.$axios.get(getDeviceList, { params }).then((res) => {
        const { records } = res.data;
        this.deviceNameList = records;
      });
    },
    handelNavigation(item) {
      this.$router.push({
        name: "pathPlaning",
        query: {
          spaceId: item.pathId,
          serviceZone: item.serviceZone,
        },
      });
    },
    handelClickMajorType() {
      this.isShowMajorType = true;
    },
    handelClickDevice() {
      this.isShowDevice = true;
    },
    confirm(node) {
      this.detailData.majorType = node.value;
      this.isShowMajorType = false;
    },
    confirmDevice(node) {
      this.detailData.deviceName = node.deviceName;
      this.detailData.deviceStatus = node.status;
      this.isShowDevice = false;
    },
    clickOverlay() {
      this.isShowMajorType = false;
    },
    clickOverlayDevice() {
      this.isShowDevice = false;
    },
    cancelPick() {
      this.isShowMajorType = false;
    },
    cancelPickDevice() {
      this.isShowDevice = false;
    },
    saveOrUpdateWorkInfo() {
      let params = {};
      if (this.detailData.status == 2) {
        params = {
          optUser: "121212454",
          getForm: {
            id: this.detailData.id,
            sceneDescription: this.detailData.sceneDescription,
            scenePic: this.detailData.scenePic,
          },
        };
      } else {
        params = {
          optUser: "121212454",
          receiveForm: {
            id: this.detailData.id,
            deviceName: this.detailData.deviceName,
            status: this.detailData.deviceStatus,
            majorType: this.detailData.majorType,
            repairDescription: this.detailData.repairDescription,
            repairPic: this.detailData.repairPic,
          },
        };
      }
      this.$axios.post(saveOrUpdateWorkInfo, params).then((res) => {
        if (res.code == 200) {
          this.$router.go(-1);
          console.log("----->", res);
        }
      });
    },
    async getWorkInfoById(id) {
      let res = await this.$axios.get(getMaintenanceTaskByIdUrl, {
        params: {
          id,
        },
      });
      if (res.code == 200) {
        const { status } = res.data;
        console.log("status----->", status);
        this.detailData = res.data;
        if (this.detailData.status != 0) {
          this.form.content = this.detailData.content;
          this.form.image = this.detailData.image;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.deviceMaintenanceDetail {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f8f8f8;

  .detail {
    width: 100%;
    box-sizing: border-box;
    background: #f8f8f8;
    .info {
      background-image: url("../img/bg.png");
      background-repeat: no-repeat;
      background-size: 100% 122%;
      padding: 54px 30px 22px 30px;
      box-sizing: border-box;
      position: relative;
      .infoBan {
        width: 254px;
        height: 228px;
        position: absolute;
        right: 30px;
        bottom: 100px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .info-title {
        margin-bottom: 38px;
        font-size: 32px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
      }
      .info-title2 {
        margin-bottom: 28px;
        font-size: 32px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
      }
      .label {
        display: flex;
        margin-bottom: 20px;
        span {
          font-size: 28px;
          width: 150px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
        }
        .labelCo {
          font-size: 28px;
          flex: 1;
          word-wrap: break-word;
          line-height: 34px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .arealabel {
        font-size: 28px;
        align-items: flex-start;
        line-height: 26px;
      }
    }
    .content {
      padding: 0 30px 24px 30px;
      width: 100%;
      box-sizing: border-box;

      .infoSure {
        background: #ffffff;
        border-radius: 16px;
        padding: 28px 34px 44px 34px;
        box-sizing: border-box;
        .infoSure-body {
          position: relative;
        }
        .btnRoute {
          width: 150px;
          height: 62px;
          background: rgba(255, 255, 255, 0.8);
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          margin: auto;
          border-radius: 33px;
          display: flex;
          align-items: center;
          justify-content: center;
          .btn-l {
            font-size: 28px;
            font-weight: 400;
            color: #fe4416;
            line-height: 62px;
            margin-right: 6px;
          }
          .btn-r {
            width: 40px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .title {
          font-size: 32px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 44px;
          margin-bottom: 40px;
        }
        .item {
          display: flex;
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 56px;
          .item-r {
            width: 334px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
      .steps {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #ffffff;
        border-radius: 16px;
        padding: 28px 34px 44px 34px;
        box-sizing: border-box;
        .title {
          margin-bottom: 20px;
        }
        .steps-content {
          flex: 1;
          .van-steps--vertical {
            padding: 0 0 0 45px;
            box-sizing: border-box;
          }
          .status-icon {
            width: 26px;
            height: 26px;
            text-align: center;
            line-height: 26px;
            background: #efefef;
            border-radius: 50%;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #c1c1c1;
            &.active-icon {
              color: #ffffff;
              background: #fe4416;
            }
          }
          .finish-icon {
            width: 26px;
            height: 26px;
          }

          .text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 40px;
          }
          .time {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 34px;
          }
          ::v-deep .van-hairline {
            &::after {
              content: none;
            }
          }
        }
      }
      .address {
        width: 100%;
        min-height: 174px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 30px 24px 32px 34px;
        margin-block: 22px;
        .address-info {
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .address-info-text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
            &.text {
              color: #fe4416;
            }
          }
          .navigation {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            span {
              margin-right: 10px;
            }
            img {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
      .fault-message {
        width: 100%;
        margin-top: 22px;
        min-height: 856px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 30px 34px 60px 34px;
        margin-bottom: 22px;
        .form-item {
          width: 100%;
          box-sizing: border-box;
          .item {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item-l {
              font-size: 28px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              line-height: 40px;
            }
            .item-r {
              width: 400px;
              height: 60px;
              box-sizing: border-box;
              /deep/ .v-dropDownMenu {
                height: 100%;
                .van-dropdown-menu {
                  height: 100%;
                  border: 1px solid #999;
                  border-radius: 10px;
                }
                .van-dropdown-menu__bar {
                  height: 100%;
                  border-radius: 10px;
                  .van-dropdown-menu__title {
                    width: 90%;
                  }
                }
              }
              /deep/ .van-dropdown-menu__bar {
                box-shadow: none;
              }
            }
          }
          .item1 {
            .item-r {
              display: flex;
              align-items: center;
            }
            .txt {
              width: 370px;
              font-size: 28px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
              line-height: 40px;
              margin-right: 10px;
              text-align: right;
            }
            .img {
              width: 12px;
              height: 18px;
              font-size: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .form-item-title {
            margin-top: 44px;
            margin-bottom: 22px;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
          }
          .select {
            width: 100%;
            height: 68px;
            background: #f3f3f3;
            border-radius: 10px;
            padding: 0 36px 0 26px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            > div {
              flex: 1;
              height: 100%;
              line-height: 68px;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            img {
              flex-shrink: 0;
              width: 19px;
              height: 13px;
            }
          }
          .textarea {
            height: 174px;
            background: #f3f3f3;
            border-radius: 10px;
            overflow: hidden;
            > div {
              height: 100%;
              width: 100%;
            }
            ::v-deep .van-cell {
              height: 100%;
              width: 100%;
              background: #f3f3f3;
              .van-field__body {
                width: 100%;
                height: 100%;
                textarea {
                  height: 100%;
                }
              }
              input.van-field__control::-webkit-input-placeholder {
                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
    }
  }
  .title {
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 44px;
  }

  .footer {
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
    padding: 48px 30px 48px 0;
    display: flex;
    justify-content: right;
    .btn {
      width: 226px;
      height: 74px;
      background: #2f7bff;
      border-radius: 44px;
      font-size: 30px;
      font-weight: 600;
      color: #ffffff;
      line-height: 74px;
      text-align: center;
    }
  }
}
</style>
