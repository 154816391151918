//分页维保任务
const findMaintenanceTaskPageUrl = `/gateway/blade-system/maintenance/findMaintenanceTaskPage`;
const getMaintenanceTaskByIdUrl = `/gateway/blade-system/maintenance/getMaintenanceTaskById`;

//根据ID获取巡检任务(移动端)
const getPatrolTaskDetailByIdUrl = `/gateway/blade-system/patrolTask/getPatrolTaskDetailById`;
//提交完成巡检
const inputPatrolTaskDetailUrl = `/gateway/blade-system/maintenance/saveOrUpdateMaintenanceTask`;
const findPatrolTaskPageUrl = `/gateway/blade-system/patrolTask/findPatrolTaskPage`;
export {
  getMaintenanceTaskByIdUrl,
  findPatrolTaskPageUrl,
  findMaintenanceTaskPageUrl,
  inputPatrolTaskDetailUrl,
  getPatrolTaskDetailByIdUrl,
};
